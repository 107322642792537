import React from "react";
import classes from "./styles/ComingSoon.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { modalActions } from "store-redux/modal-slice";
import Button from "components/UI/button/Button";
import Signup from "components/modal/sign-up/Signup";
import SignIn from "components/modal/sign-in/SignIn";
import { useNavigate } from "react-router-dom";

const CommingSoon = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { modalIsVisible, modalType } = useSelector((state) => state.modal);
  console.log("777 modalIsVisible, modaltype", modalIsVisible, modalType);
  const { user, isLoggedIn } = useSelector((state) => state.user);

  const openSignUpModal = () => {
    dispatch(
      modalActions.changeModalComponent({
        modalType: "signup",
      })
    );
  };

  const toggleModalHandler = () => {
    // toggle the visability of the Modal
    dispatch(modalActions.toggleModal());
  };

  return (
    <>
      <div
        className={classes.container}
        style={{
          backgroundImage: `url(/news-img/coming/bg.webp)`,
          background:
            " linear-gradient(rgba(0, 0, 0, 0.26), rgb(0, 0, 0)), url(/news-img/coming/bg.webp)",
        }}
      >
        <nav className={classes.navbar}>
          <img
            src="/news-img/coming/nav-icon.svg"
            alt=""
            className={classes.navImage}
          />
        </nav>
        <main className={classes.mainContent}>
          <div className={classes.contentWrapper}>
            <img
              src="/news-img/coming/nav-icon.svg"
              alt=""
              className={classes.contentImage}
            />
            {/* <h3 className={classes.comingSoon}>Coming Soon...</h3> */}
            <div className={classes.buttonsSection}>
              {!isLoggedIn && (
                <Button
                  className={classes.loginButton}
                  onClick={() => openSignUpModal()}
                >
                  {t("register_here")}
                </Button>
              )}
              {isLoggedIn && (
                <Button
                  className={classes.loginButton}
                  onClick={() => navigate(`/my-account`)}
                >
                  {t("my_account")}
                </Button>
              )}
            </div>
            <h3 className={classes.platform}>Oumuamua News Platform</h3>
            <div className={classes.rowSection}>
              <div className={classes.colSection}>
                <h4>Our Vision</h4>
                <p>
                  To identify and counter published misinformation and
                  disinformation.
                </p>
              </div>
              <div className={classes.colSection}>
                <h4>Our Mission</h4>
                <p>
                  Humanitarian Information platform with Integrity and without
                  bias!
                </p>
              </div>
            </div>
            <div className={classes.infoSection}>
              <h4 className={classes.textInfo}>
                We appreciate your overwhelming support and patience as we have
                received a flood of requests with interest in our platform.{" "}
                <br />
                <br />
                Oumuamua News Support Team <br />
                info@oumuamua.news
              </h4>
            </div>
            <div className={classes.socialSection}>
              <a href="https://www.facebook.com/profile.php?id=61564670775562">
                <FontAwesomeIcon
                  icon={faFacebook}
                  className="fab fa-facebook"
                />
              </a>
              <a href="https://x.com/OumuamuaN1663">
                <FontAwesomeIcon icon={faTwitter} />
              </a>
              <a href="https://www.linkedin.com/company/oumuamua-news">
                <FontAwesomeIcon icon={faLinkedin} />
              </a>
              <a href="https://www.instagram.com/oumuamuanews/">
                <FontAwesomeIcon icon={faInstagram} />
              </a>
            </div>
            {/* <div className={classes.buttonsSection}>
              {!isLoggedIn && (
                <Button
                  className={classes.loginButton}
                  onClick={() => openSignUpModal()}
                >
                  {t("register_here")}
                </Button>
              )}
              {isLoggedIn && (
                <Button
                  className={classes.loginButton}
                  onClick={() => navigate(`/my-account`)}
                >
                  {t("my_account")}
                </Button>
              )}
            </div> */}
          </div>
        </main>
        <footer className={classes.footer}>
          {" "}
          © 2025. All rights reserved{" "}
        </footer>
        {modalIsVisible && modalType === "signup" && (
          <Signup
            // showSignupDetailsModal={setSignupDetailsModalHandler}
            onClose={toggleModalHandler}
          />
        )}
        {modalIsVisible && modalType == "signin" && (
          <SignIn onClose={toggleModalHandler} />
        )}
      </div>
    </>
  );
};

export default CommingSoon;
